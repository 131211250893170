import { Component, Fragment } from "react";

import Footer from '../component/layout/footer';
import HeaderTwo from '../component/layout/headertwo';
import AboutThree from '../component/section/aboutthree';
import AboutTwo from '../component/section/abouttwo';
import AppSectionTwo from "../component/section/appsectiontwo";
import BannerTwo from '../component/section/bannertwo';
import GroupTwo from "../component/section/grouptwo";
import MemberTwo from '../component/section/membertwo';
import Story from '../component/section/story';

class HomeTwo extends Component {
    render() { 
        return (
            <Fragment>
                <HeaderTwo />
                <BannerTwo />
                <AboutTwo />
                <AboutThree />
                <Story />
                <MemberTwo />
                <GroupTwo />
                <AppSectionTwo />
                <Footer />
            </Fragment>
        );
    }
}
 
export default HomeTwo;