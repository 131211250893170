import { Component } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";


let BannerSliderList = [
    {
        bgImg: 'assets/images/banner/bg3.jpg',
        title: 'Thousands of singles are waitig for yor online now.',
        desc: 'Receive lots of attention form attractive Members Worldwide Join the secure & Easy Way!',
        btnText: 'Get Connected Today',
        className: 'row align-items-end',
    },
    {
        bgImg: 'assets/images/banner/bg4.jpg',
        title: 'Thousands of singles are waitig for yor online now.',
        desc: 'Receive lots of attention form attractive Members Worldwide Join the secure & Easy Way!',
        btnText: 'Get Connected Today',
        className: 'row align-items-end flex-row-reverse',
    },
    {
        bgImg: 'assets/images/banner/bg5.jpg',
        title: 'Thousands of singles are waitig for yor online now.',
        desc: 'Receive lots of attention form attractive Members Worldwide Join the secure & Easy Way!',
        btnText: 'Get Connected Today',
        className: 'row align-items-end flex-row-reverse',
    },
]


class BannerThree extends Component {
    render() { 
        return (
            <div className="banner-slider overflow-hidden position-relative">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    navigation={{
                        prevEl: '.banner-slider-prev',
                        nextEl: '.banner-slider-next',
                    }}
                    loop={true}
                    modules={[Autoplay, Navigation]}
                >
                    {BannerSliderList.map((val, i) => (
                        <SwiperSlide key={i}>
                            <div className="banner-section style2 bgimg" style={{backgroundImage: `url(${val.bgImg})`}}>
                                <div className="container">
                                    <div className="section-wrapper">
                                        <div className={val.className}>
                                            <div className="col-xl-6">
                                                <div className="banner-content">
                                                    <div className="intro-form">
                                                        <h2>{val.title}</h2>
                                                        <p>{val.desc}</p>
                                                        <Link to="/signup" className="lab-btn"><span>{val.btnText}</span></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="banner-slider-next"><i className="icofont-rounded-right"></i></div>
                <div className="banner-slider-prev"><i className="icofont-rounded-left"></i></div>
            </div>
        );
    }
}
 
export default BannerThree;