import { Component } from "react";

const title = "02 Comment";

let CommentsList = [
    {
        imgUrl: 'assets/images/member/02.jpg',
        imgAlt: 'author thumb',
        Name: 'Umme Nishat',
        Date: 'May 15, 2022 at 12:41 pm',
        Massage: 'The inner sanctuary, I throw myself down among the tall grass bye the trckli stream and, as I lie close to the earth',
    },
    {
        imgUrl: 'assets/images/member/03.jpg',
        imgAlt: 'author thumb',
        Name: 'Rajib Raj',
        Date: 'May 15, 2022 at 12:41 pm',
        Massage: 'The inner sanctuary, I throw myself down among the tall grass bye the trckli stream and, as I lie close to the earth',
    },
]

class Comments extends Component {
    render() { 
        return (
            <div id="comments" className="comments">
                <div className="widget-title">
                    <h4>{title}</h4>
                </div>
                <ul className="comment-list">
                    {CommentsList.map((val, i) => (
                        <li className="comment" key={i}>
                            <div className="com-image">
                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                            </div>
                            <div className="com-content">
                                <div className="com-title">
                                    <div className="com-title-meta">
                                        <h6><a href="#" rel="external nofollow" className="url">{val.Name}</a></h6>
                                        <span> {val.Date} </span>
                                    </div>
                                    <span className="reply">
                                        <a rel="nofollow" className="comment-reply-link" href="#"><i className="icofont-reply-all"></i> Reply</a>
                                    </span>
                                </div>
                                <p>{val.Massage}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
 
export default Comments;