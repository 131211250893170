import { Component } from "react";

const title = "All Categories";

let CategoryList = [
    {
        link: '#',
        text: 'Show all',
        count: '18',
    },
    {
        link: '#',
        text: 'Business',
        count: '20',
    },
    {
        link: '#',
        text: 'Creative',
        count: '15',
    },
    {
        link: '#',
        text: 'Inspiation',
        count: '30',
    },
    {
        link: '#',
        text: 'News',
        count: '25',
    },
    {
        link: '#',
        text: 'Photography',
        count: '12',
    },
    {
        link: '#',
        text: 'Smart',
        count: '26',
    },
]

class Category extends Component {
    render() { 
        return (
            <div className="widget widget-category">
                <div className="widget-header">
                    <h5>{title}</h5>
                </div>
                <ul className="lab-ul widget-wrapper list-bg-none">
                    {CategoryList.map((val, i) => (
                        <li key={i}>
                            <a href={val.link} className="d-flex flex-wrap justify-content-between"><span><i className="fa-solid fa-angles-right"></i>{val.text}</span><span>{val.count}</span></a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
 
export default Category;