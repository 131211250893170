import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const title = "Register Now";
const socialTitle = "Register With Social Media";

let SocialList = [
    {
        iconName: 'icofont-facebook-messenger',
        link: '#',
        className: 'facebook',
    },
    {
        iconName: 'icofont-twitter',
        link: '#',
        className: 'twitter',
    },
    {
        iconName: 'icofont-linkedin',
        link: '#',
        className: 'linkedin',
    },
    {
        iconName: 'icofont-instagram',
        link: '#',
        className: 'instagram',
    },
    {
        iconName: 'icofont-pinterest',
        link: '#',
        className: 'pinterest',
    },
]

class SignUp extends Component {
    constructor(props){
        super(props);
        this.state = {
            regName: '',
            regEmail: '',
            regPassword: '',
            regConPassword: '',
        };
    }
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Registation Page'} curPage={'Sign Up'} />
                <div className="login-section padding-tb">
                    <div className="container">
                        <div className="account-wrapper">
                            <h3 className="title">{title}</h3>
                            <form className="account-form">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        value={this.state.regName}
                                        onChange={(e)=>{this.setState({regName: e.target.value});}}
                                        placeholder="Enter Your Usewrname *"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        id="item02"
                                        value={this.state.regEmail}
                                        onChange={(e)=>{this.setState({regEmail: e.target.value});}}
                                        placeholder="Enter Your Email *"
                                        className="my-form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        name="password"
                                        id="item03"
                                        value={this.state.regPassword}
                                        onChange={(e)=>{this.setState({regPassword: e.target.value});}}
                                        placeholder="Enter Your Password *"
                                        className="my-form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        name="password"
                                        id="item04"
                                        value={this.state.regConPassword}
                                        onChange={(e)=>{this.setState({regConPassword: e.target.value});}}
                                        placeholder="Enter Your Password *"
                                        className="my-form-control"
                                    />
                                </div>
                                <div className="form-group">
                                    <button className="d-block lab-btn"><span>Get Started Now</span></button>
                                </div>
                            </form>
                            <div className="account-bottom">
                                <span className="d-block cate pt-10">Are you a member? <Link to="/login">Login</Link></span>
                                <span className="or"><span>or</span></span>
                                <h5 className="subtitle">{socialTitle}</h5>
                                <ul className="social-media social-color justify-content-center d-flex lab-ul">
                                    {SocialList.map((val, i) => (
                                        <li key={i}>
                                            <a href={val.link} className={val.className}><i className={val.iconName}></i></a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}
 
export default SignUp;