import { Component } from "react";

class SelectColor extends Component {
    render() { 
        const {select} = this.props;
        return (
            <select defaultValue={select}>
                <option value="Pink">Pink</option>
                <option value="Ash">Ash</option>
                <option value="Red">Red</option>
                <option value="White">White</option>
                <option value="Blue">Blue</option>
            </select>
        );
    }
}
 
export default SelectColor;