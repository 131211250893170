import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GoogleMap from "../component/sidebar/googlemap";

const title = "Get Information";
const desc = "Our Contact information Details and Follow us on social media";

const conTitle = "Don't Be A Stranger Just Say Hello.";
const conDesc = "We do fast phone repair. In most to repair your device in just minutes, li we’ll normally get con nection inutes, we’ll normally ge.";
const btnText = "Send Our Message";

let InfoList = [
    {
        imgUrl: 'assets/images/contact/01.png',
        imgAlt: 'Dathing Thumb',
        text: 'Office Address',
        desc: '1201 park street, Fifth Avenue',
    },
    {
        imgUrl: 'assets/images/contact/02.png',
        imgAlt: 'Dathing Thumb',
        text: 'Phone Number',
        desc: '+22698 745 632,02 982 745',
    },
    {
        imgUrl: 'assets/images/contact/03.png',
        imgAlt: 'Dathing Thumb',
        text: 'Send Mail',
        desc: 'yourmail@gmail.com',
    },
    {
        imgUrl: 'assets/images/contact/04.png',
        imgAlt: 'Dathing Thumb',
        text: 'Our Website',
        desc: 'www.sitename.com',
    },
]

class ContactPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactSubject: '',
            contactNumber: '',
            contactMassage: '',
        };
    }
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Contact Us'} curPage={'Contact Us'} />
                <div className="contact-section">
                    <div className="contact-top padding-tb aside-bg padding-b">
                        <div className="container">
                            <div className="row g-4 flex-row-reverse">
                                <div className="col-lg-4">
                                    <div className="contact-info-wrapper">
                                        <div className="contact-info-title">
                                            <h5>{title}</h5>
                                            <p>{desc}</p>
                                        </div>
                                        <div className="contact-info-content">
                                            {InfoList.map((val, i) => (
                                                <div className="contact-info-item" key={i}>
                                                <div className="contact-info-inner">
                                                    <div className="contact-info-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="contact-info-details">
                                                        <span className="fw-bold">{val.text}</span>
                                                        <p>{val.desc}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <article className="contact-form-wrapper">
                                        <div className="contact-form">
                                            <h4>{conTitle}</h4>
                                            <p className="mb-4">{conDesc}</p>
                                            <form className="comment-form">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="item01"
                                                    value={this.state.contactName}
                                                    onChange={(e)=>{this.setState({contactName: e.target.value});}}
                                                    placeholder="Your Name *"
                                                />
                                                <input
                                                    type="text"
                                                    name="email"
                                                    id="item02"
                                                    value={this.state.contactEmail}
                                                    onChange={(e)=>{this.setState({contactEmail: e.target.value});}}
                                                    placeholder="Your Email *"
                                                />
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    id="item03"
                                                    value={this.state.contactSubject}
                                                    onChange={(e)=>{this.setState({contactSubject: e.target.value});}}
                                                    placeholder="Your Subject *"
                                                />
                                                <input
                                                    type="text"
                                                    name="number"
                                                    id="item04"
                                                    value={this.state.contactNumber}
                                                    onChange={(e)=>{this.setState({contactNumber: e.target.value});}}
                                                    placeholder="Mobile Number *"
                                                />
                                                <textarea 
                                                    rows="10" 
                                                    type="text"
                                                    id="item05"
                                                    name="message"
                                                    value={this.state.contactMassage}
                                                    onChange={(e)=>{this.setState({contactMassage: e.target.value});}}
                                                    placeholder="Your Message"
                                                ></textarea>
                                                <button type="submit" className="lab-btn"><span>{btnText}</span></button>
                                            </form>
                                            <p className="form-message"></p>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-bottom">
                        <div className="contac-bottom">
                            <div className="row justify-content-center g-0">
                                <div className="col-12">
                                    <div className="location-map">
                                        <GoogleMap />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}
 
export default ContactPage;