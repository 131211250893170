import { Component, Fragment } from "react";

import Footer from '../component/layout/footer';
import HeaderTwo from '../component/layout/headertwo';
import AboutFive from "../component/section/aboutfive";
import About from "../component/section/about";
import AppSectionTwo from "../component/section/appsectiontwo";
import BannerThree from "../component/section/bannerthree";
import GroupThree from "../component/section/groupthree";
import MeetTwo from "../component/section/meettwo";
import Member from '../component/section/member';
import Work from "../component/section/work";


class HomeThree extends Component {
    render() { 
        return (
            <Fragment>
                <HeaderTwo />
                <BannerThree />
                <Member />
                <About />
                <GroupThree />
                <AboutFive />
                <MeetTwo />
                <Work />
                <AppSectionTwo />
                <Footer />
            </Fragment>
        );
    }
}
 
export default HomeThree;