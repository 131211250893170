import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Archive from "../component/sidebar/archive";
import Category from "../component/sidebar/category";
import CommentForm from "../component/sidebar/commentform";
import Comments from "../component/sidebar/comments";
import RecentPost from "../component/sidebar/recent-post";
import Search from "../component/sidebar/search";
import Tags from "../component/sidebar/tags";

class BlogSingle extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'A wonderf serenity has taken'} curPage={'Blog Details'} />
                <section className="blog-section padding-tb pb-lg-110">
                    <div className="container">
                        <div className="main-blog">
                            <div className="row g-4">
                                <div className="col-xl-8 col-12">
                                    <div className="blog-wrapper">
                                        <div className="post-item">
                                            <div className="post-item-inner">
                                                <div className="post-thumb">
                                                    <img src="assets/images/blog/03.jpg" alt="blog" />
                                                </div>
                                                <div className="post-content">
                                                    <span className="meta">By <a href="#">Admin</a> March 24, 2021</span>
                                                    <h3>A wonderf serenity has taken poesion of my entire souin like these sweet mornins</h3>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore ipsa suscipit reprehenderit sint, aliquid repudiandae id deserunt vitae debitis molestias perspiciatis consectetur rem quibusdam? Voluptates ali doloribus culpa, ipsum a fugit voluptatem, vel mollitia vero minus accusamus magnam similique earume rerum, dolorem officia consequuntur provident molestiae veritatis. Accusamus ab, doloribus corrupti out veritatis quis dolores illo aliquam error nobis, hic tenetur.</p>
                                                    <blockquote>
                                                        <p>Steal into The inner Sanc Thro Myse Down Amon The Hall Gras Buzz The Little World The Amon The Staks And Grow Famar With Count And Fies Then The Presence of The Almigh Among The Staks vitae debitis molestias.</p>
                                                    </blockquote>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore ipsa suscipit reprehenderit sint, aliquid repudiandae id deserunt vitae debitis molestias perspiciatis consectetur rem quibusdam? Voluptates ali doloribus culpa, ipsum a fugit voluptatem, vel mollitia vero minus accusamus magnam similique earume rerum, dolorem officia consequuntur provident molestiae veritatis. Accusamus ab, doloribus corrupti out veritatis quis dolores illo aliquam error nobis, hic tenetur.</p>
                                                </div>
                                                <div className="tags-section">
                                                    <ul className="tags">
                                                        <li><span><i className="icofont-tags"></i></span></li>
                                                        <li><a href="#">Dantal</a></li>
                                                        <li><a href="#">Health Care</a></li>
                                                        <li><a href="#">Company</a></li>
                                                        <li><a href="#">Corporate</a></li>
                                                    </ul>
                                                    <ul className="social-link-list d-flex flex-wrap">
                                                        <li><a href="#" className="facebook"><i className="icofont-facebook"></i></a></li>
                                                        <li><a href="#" className="dribble"><i className="icofont-dribble"></i></a></li>
                                                        <li><a href="#" className="twitter"><i className="icofont-twitter"></i></a></li>
                                                        <li><a href="#" className="linkedin"><i className="icofont-linkedin"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <Comments />
                                        <CommentForm />
                                    </div>
                                </div>

                                <div className="col-xl-4 col-12">
                                    <aside className="mt-5 mt-xl-0">
                                        <Search />
                                        <Category />
                                        <RecentPost />
                                        <Archive />
                                        <Tags />
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}
 
export default BlogSingle;