import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Pagination from "../component/layout/pagination";

let GroupsList = [
    {
        imgUrl: 'assets/images/group/01.jpg',
        imgAlt: 'Dating Thumb',
        title: 'A Dating Story',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/02.jpg',
        imgAlt: 'Dating Thumb',
        title: 'A Single Rose',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/03.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Beautiful Distraction',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/04.jpg',
        imgAlt: 'Dating Thumb',
        title: 'A Dating Story',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/05.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Because You Love Me',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/06.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Breakin’ Up Is Hard To Do',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/07.jpg',
        imgAlt: 'Dating Thumb',
        title: 'A Dating Story',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/08.jpg',
        imgAlt: 'Dating Thumb',
        title: 'A Single Rose',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/09.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Beautiful Distraction',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/10.jpg',
        imgAlt: 'Dating Thumb',
        title: 'A Dating Story',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/11.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Because You Love Me',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/12.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Breakin’ Up Is Hard To Do',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/13.jpg',
        imgAlt: 'Dating Thumb',
        title: 'A Dating Story',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/14.jpg',
        imgAlt: 'Dating Thumb',
        title: 'A Single Rose',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/15.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Beautiful Distraction',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/16.jpg',
        imgAlt: 'Dating Thumb',
        title: 'A Dating Story',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/17.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Because You Love Me',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/18.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Breakin’ Up Is Hard To Do',
        desc: 'Distinctively benchmark economically sound value before exceptional supply chains.',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
]

class ActiveGroup extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'All Groups'} curPage={'Groups'} />
                <section className="group-page-section group-section style2 padding-tb">
                    <div className="container">
                        <ul className="group-search">
                            <li className="group-count">
                                <p>All Groups</p>
                                <p>36</p>
                            </li>
                            <li className="group-search-btn">
                                <form>
                                    <input type="text" placeholder="Search Group Name..." />
                                    <div className="input-button">
                                        <input type="submit" value="Search Now" />
                                    </div>
                                </form>

                            </li>
                        </ul>
                        <div className="groups-wrapper">
                            <div className="row g-4 row-cols-sm-2 row-cols-1">
                                {GroupsList.map((val, i) => (
                                    <div className="col" key={i}>
                                        <div className="group-item lab-item">
                                            <div className="lab-inner">
                                                <div className="lab-thumb">
                                                    <Link to="/group-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                                </div>
                                                <div className="lab-content">
                                                    <Link to="/group-single"><h4>{val.title}</h4></Link>
                                                    <p>{val.desc}</p>
                                                    <ul className="lab-content-icon">
                                                        {val.groupLabel.map((val, i) => (
                                                            <li key={i}>
                                                                <i className={val.iconName}></i>
                                                                <div className="time-tooltip">
                                                                    <div className="time-tooltip-holder">
                                                                        <span className="tooltip-label">{val.text}</span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Pagination />
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}
 
export default ActiveGroup;