import { Component } from "react";
import CountUp from 'react-countup';

const title = "It All Starts With A Date";
const desc = "Become part of a growing a community and have access the thousands of people.";


let AboutContentList = [
    {
        imgUrl: 'assets/images/about/01.png',
        imgAlt: 'Dathing Thumb',
        count: '29991',
        text: 'Members in Total',
    },
    {
        imgUrl: 'assets/images/about/02.png',
        imgAlt: 'Dathing Thumb',
        count: '29991',
        text: 'Members Online',
    },
    {
        imgUrl: 'assets/images/about/03.png',
        imgAlt: 'Dathing Thumb',
        count: '14991',
        text: 'Men Online',
    },
    {
        imgUrl: 'assets/images/about/04.png',
        imgAlt: 'Dathing Thumb',
        count: '16991',
        text: 'Women Online',
    },
]

class About extends Component {
    render() { 
        return (
            <section className="about-section padding-tb bgimg" style={{backgroundImage: "url(/assets/images/bg-img/01.jpg)"}}>
                <div className="container">
                    <div className="section-header">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="section-wrapper">
                        <div className="row justify-content-center g-4">
                            {AboutContentList.map((val, i) => (
                                <div className="col-xl-3 col-lg-4 col-sm-6 col-12" key={i}>
                                    <div className="lab-item about-item">
                                        <div className="lab-inner text-center">
                                            <div className="lab-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="lab-content">
                                                <h2 className="counter"><CountUp end={`${val.count}`} /></h2>
                                                <p>{val.text}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;
