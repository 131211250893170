import { Component } from "react";
import { Link } from "react-router-dom";

const title = "Be Part of Real Group";
const desc = "Listen and learn from our community members and find out tips and tricks to meet your love. Join us and be part of a bigger family.";
const btnText = "Discover All Group";

let GroupContentList = [
    {
        imgUrl: 'assets/images/group/01.jpg',
        imgAlt: 'Dating Thumb',
        title: 'A Dating Story',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/02.jpg',
        imgAlt: 'Dating Thumb',
        title: 'A Single Rose',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/03.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Beautiful Distraction',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/04.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Because You Love Me',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
]

class Group extends Component {
    render() { 
        return (
            <section className="group-section padding-tb">
                <div className="container">
                    <div className="section-header">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 row-cols-xl-4 row-cols-sm-2 row-cols-1">
                            {GroupContentList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="group-item lab-item">
                                        <div className="lab-inner">
                                            <div className="lab-thumb">
                                                <Link to="/group-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                            </div>
                                            <div className="lab-content text-center">
                                                <Link to="/group-single"><h4>{val.title}</h4></Link>
                                                <ul className="lab-content-icon">
                                                    {val.groupLabel.map((val, i) => (
                                                        <li key={i}>
                                                            <i className={val.iconName}></i>
                                                            <div className="time-tooltip">
                                                                <div className="time-tooltip-holder">
                                                                    <span className="tooltip-label">{val.text}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="d-flex flex-wrap justify-content-center mt-5">
                            <Link to="/active-group" className="lab-btn"><span>{btnText}</span></Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Group;