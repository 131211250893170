import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Pagination from "../component/layout/pagination";
import Archive from "../component/sidebar/archive";
import Category from "../component/sidebar/category";
import RecentPost from "../component/sidebar/recent-post";
import Search from "../component/sidebar/search";
import Tags from "../component/sidebar/tags";

let PostContentList = [
    {
        imgUrl: 'assets/images/blog/01.jpg',
        imgAlt: 'Blog Thumb',
        author: 'Rajib Raj',
        date: 'May 25, 2022',
        title: 'Uompe Qrear High Ecent Nche Without Some Prin Uomp Without Some Qreari',
        desc: 'Rapidious qntegrate distrbuted supply chains throuih marke position bestn practces chain marke positonn bestin practcer ieractvel fashon and sound qources forin iteractve fashion bestin practce ieractve and low sound qources for bestin practcer ieractvel fashon.',
        btnText: 'Read More',
        like: 'Like',
        likeCount: '12',
        comment: 'Comments',
        commentCount: '24',
    },
]

let SliderPostContentList = [
    {
        author: 'Rajib Raj',
        date: 'May 25, 2022',
        title: 'Uompe Qrear High Ecent Nche Without Some Prin Uomp Without Some Qreari',
        desc: 'Rapidious qntegrate distrbuted supply chains throuih marke position bestn practces chain marke positonn bestin practcer ieractvel fashon and sound qources forin iteractve fashion bestin practce ieractve and low sound qources for bestin practcer ieractvel fashon.',
        btnText: 'Read More',
        like: 'Like',
        likeCount: '12',
        comment: 'Comments',
        commentCount: '24',
        imgSlider: [
            {
                imgUrl: 'assets/images/blog/03.jpg',
                imgAlt: 'Blog Thumb',
            },
            {
                imgUrl: 'assets/images/blog/02.jpg',
                imgAlt: 'Blog Thumb',
            },
            {
                imgUrl: 'assets/images/blog/01.jpg',
                imgAlt: 'Blog Thumb',
            },
        ]
    },

]

// let VideoPostContentList = [
//     {
//         videoLink: 'https://www.youtube.com/embed/3iIcqKKs6kY',
//         author: 'Rajib Raj',
//         date: 'May 25, 2022',
//         title: 'Uompe Qrear High Ecent Nche Without Some Prin Uomp Without Some Qreari',
//         desc: 'Rapidious qntegrate distrbuted supply chains throuih marke position bestn practces chain marke positonn bestin practcer ieractvel fashon and sound qources forin iteractve fashion bestin practce ieractve and low sound qources for bestin practcer ieractvel fashon.',
//         btnText: 'Read More',
//         like: 'Like',
//         likeCount: '12',
//         comment: 'Comments',
//         commentCount: '24',
//     },

// ]

let QuotePostContentList = [
    {
        imgUrl: 'assets/images/blog/icon/code.png',
        imgAlt: 'Blog Qoute',
        quoteText: 'Rapidious qntegrate distrbuted supply chains throuih marke position bestn practces chain marke positonn bestin practcer ieractvel fashon and sound qources forin iteractve fashion bestin practce ieractve and low sound qources for bestin practcer ieractvel fashon.',
        author: 'Rajib Raj',
        date: 'May 25, 2022',
        title: 'Uompe Qrear High Ecent Nche Without Some Prin Uomp Without Some Qreari',
        desc: 'Rapidious qntegrate distrbuted supply chains throuih marke position bestn practces chain marke positonn bestin practcer ieractvel fashon and sound qources forin iteractve fashion bestin practce ieractve and low sound qources for bestin practcer ieractvel fashon.',
        btnText: 'Read More',
        like: 'Like',
        likeCount: '12',
        comment: 'Comments',
        commentCount: '24',
    },
]

class BlogPage extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Blog'} curPage={'Blog'} />
                <section className="blog-section padding-tb">
                    <div className="container">
                        <div className="main-blog">
                            <div className="row g-4">
                                <div className="col-xl-8 col-12">
                                    <div className="blog-wrapper">
                                        {PostContentList.map((val, i) => (
                                            <div className="post-item" key={i}>
                                                <div className="post-item-inner">
                                                    <div className="post-thumb">
                                                        <a href="/blog-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></a>
                                                    </div>
                                                    <div className="post-content">
                                                        <span className="meta">By <a href="#">{val.author}</a> {val.date}</span>
                                                        <h3><Link to="/blog-single">{val.title}</Link></h3>
                                                        <p>{val.desc}</p>
                                                    </div>
                                                    <div className="blog-footer">
                                                        <a href="/blog-single" className="viewall">{val.btnText} <i className="icofont-double-right"></i></a>
                                                        <div className="right">
                                                            <a href="#" className="blog-heart"><i className="icofont-heart-alt"></i> {val.likeCount} <span className="d-none d-sm-inline-block">{val.like}</span> </a>
                                                            <a href="#" className="blog-comment"><i className="icofont-comment"></i> {val.commentCount} <span className="d-none d-sm-inline-block">{val.comment}</span> </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {SliderPostContentList.map((val, i) => (
                                            <div className="post-item post-slider" key={i}>
                                                <div className="post-item-inner">
                                                    <div className="post-thumb">
                                                        <div className="blog-slider">
                                                            <Swiper
                                                                loop={'true'}
                                                                autoplay={{
                                                                    delay: 5000,
                                                                    disableOnInteraction: false,
                                                                }}
                                                                navigation={{
                                                                    prevEl: '.blog-slider-prev',
                                                                    nextEl: '.blog-slider-next',
                                                                }}
                                                                modules={[Autoplay, Navigation]}
                                                            >
                                                                {val.imgSlider.map((val, i) => (
                                                                    <SwiperSlide key={i}>
                                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                                    </SwiperSlide>
                                                                ))} 
                                                            </Swiper>
                                                            <div className="blog-slider-next">
                                                                <i className="icofont-play"></i>
                                                            </div>
                                                            <div className="blog-slider-prev">
                                                                <i className="icofont-play"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="post-content">
                                                        <span className="meta">By <a href="/blog-single">{val.author}</a> {val.date}</span>
                                                        <h3><Link to="/blog-single">{val.title}</Link></h3>
                                                        <p>{val.desc}</p>
                                                    </div>
                                                    <div className="blog-footer">
                                                        <Link to="/blog-single" className="viewall">{val.btnText} <i className="icofont-double-right"></i></Link>
                                                        <div className="right">
                                                            <a href="#" className="blog-heart"><i className="icofont-heart-alt"></i> {val.likeCount} <span  className="d-none d-sm-inline-block">{val.like}</span> </a>
                                                            <a href="#" className="blog-comment"><i className="icofont-comment"></i> {val.commentCount} <span className="d-none d-sm-inline-block">{val.comment}</span> </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))} 

                                        {/* {VideoPostContentList.map((val, i) => (
                                            <div className="post-item video-post" key={i}>
                                                <div className="post-item-inner">
                                                    <div className="post-thumb">
                                                        <div className="embed-responsive">
                                                            <iframe src={val.videoLink}></iframe>
                                                        </div>
                                                    </div>
                                                    <div className="post-content">
                                                        <span className="meta">By <a href="#">{val.author}</a> {val.date}</span>
                                                        <h3><Link to="/blog-single">{val.title}</Link></h3>
                                                        <p>{val.desc}</p>
                                                    </div>
                                                    <div className="blog-footer">
                                                        <Link to="/blog-single" className="viewall">{val.btnText} <i className="icofont-double-right"></i></Link>
                                                        <div className="right">
                                                            <a href="#" className="blog-heart"><i className="icofont-heart-alt"></i> {val.likeCount} <span className="d-none d-sm-inline-block">{val.like}</span> </a>
                                                            <a href="#" className="blog-comment"><i className="icofont-comment"></i> {val.commentCount} <span className="d-none d-sm-inline-block">{val.commentCount}</span> </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))} */}
                                        
                                        {QuotePostContentList.map((val, i) => (
                                            <div className="post-item code-post" key={i}>
                                                <div className="post-item-inner">
                                                    <div className="post-thumb">
                                                        <div className="code-content">
                                                            <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                            <p>{val.quoteText}</p>
                                                        </div>
                                                    </div>
                                                    <div className="post-content">
                                                        <span className="meta">By <a href="#">{val.author}</a> {val.date}</span>
                                                        <h3><Link to="/blog-single">{val.title}</Link></h3>
                                                        <p>{val.desc}</p>
                                                    </div>
                                                    <div className="blog-footer">
                                                        <Link to="/blog-single" className="viewall">{val.btnText} <i className="icofont-double-right"></i></Link>
                                                        <div className="right">
                                                            <a href="#" className="blog-heart"><i className="icofont-heart-alt"></i> {val.likeCount} <span className="d-none d-sm-inline-block">{val.like}</span> </a>
                                                            <a href="#" className="blog-comment"><i className="icofont-comment"></i> {val.commentCount} <span className="d-none d-sm-inline-block">{val.comment}</span> </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <Pagination />
                                </div>

                                <div className="col-xl-4 col-12">
                                    <aside className="mt-5 mt-xl-0">
                                        <Search />
                                        <Category />
                                        <RecentPost />
                                        <Archive />
                                        <Tags />
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}
 
export default BlogPage;