import { Component } from "react";
import { Link } from "react-router-dom";

const title = <h2>Meet <span className="theme-color">big</span> And <span className="theme-color">Cute</span> Love Here.</h2>;
const btnText = "Registration Now";


let BannerTwoContentList = [
    {
        iconName: 'icofont-heart-alt',
        text: 'Friendly, efficient and simple',
    },
    {
        iconName: 'icofont-heart-alt',
        text: 'Consist on that we offer a site which is friendly',
    },
    {
        iconName: 'icofont-heart-alt',
        text: 'Find friends among a group of guys and girls',
    },
    {
        iconName: 'icofont-heart-alt',
        text: 'You can find love',
    },
    {
        iconName: 'icofont-heart-alt',
        text: 'Absolutely free',
    },
]

class BannerTwo extends Component {
    render() { 
        return (
            <section className="banner-section style2 bgimg" style={{backgroundImage: "url(/assets/images/banner/bg2.jpg)"}}>
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row align-items-end">
                            <div className="col-lg-6">
                                <div className="banner-content">
                                    <div className="intro-form">
                                        {title}
                                        <ul>
                                            {BannerTwoContentList.map((val, i) => (
                                                <li key={i}><i className={val.iconName}></i>{val.text}</li>
                                            ))}
                                        </ul>
                                        <Link to="/signup" className="lab-btn"><span>{btnText}</span></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner-thumb">
                                    <img src="assets/images/banner/01.png" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default BannerTwo;