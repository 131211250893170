import { Component } from "react";
import { Link } from "react-router-dom";

const title = "Read Our Success Stories";
const desc = "Listen and learn from our community members and find out tips and tricks to meet your love. Join us and be part of a bigger family.";

let StoryList = [
    {
        imgUrl: 'assets/images/story/01.jpg',
        imgUrlTwo: 'assets/images/story/author/01.png',
        imgAlt: 'Dathing Thumb',
        title: 'Dream places and locations to visit in 2022',
        name: 'Umme Nishat',
        date: 'April 08, 2022',
    },
    {
        imgUrl: 'assets/images/story/02.jpg',
        imgUrlTwo: 'assets/images/story/author/02.png',
        imgAlt: 'Dathing Thumb',
        title: 'Make your dreams come true and monetise quickly',
        name: 'Rajib Raj',
        date: 'April 08, 2022',
    },
    {
        imgUrl: 'assets/images/story/03.jpg',
        imgUrlTwo: 'assets/images/story/author/03.png',
        imgAlt: 'Dathing Thumb',
        title: 'Love looks not with the eyes, but with the mind.',
        name: 'Radhika Roy',
        date: 'April 08, 2022',
    },
]

class Story extends Component {
    render() { 
        return (
            <section className="story-section padding-tb bgimg" style={{backgroundImage: "url(/assets/images/bg-img/02.jpg)"}}>
                <div className=" container">
                    <div className="section-header">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="section-wrapper">
                        <div className="row justify-content-center g-4 row-cols-xl-3 row-cols-sm-2 row-cols-1">
                            {StoryList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="story-item lab-item">
                                        <div className="lab-inner">
                                            <div className="lab-thumb">
                                                <Link to="/blog-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                            </div>
                                            <div className="lab-content">
                                                <Link to="/blog-single"><h4>{val.title}</h4></Link>
                                                <div className="lab-content-author">
                                                    <div className="left">
                                                        <img src={`${val.imgUrlTwo}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="right">
                                                        {val.name}
                                                        <span>{val.date}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Story;