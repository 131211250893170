import { Component } from "react";
import { Link } from "react-router-dom";

const title = "join the group";

let GroupContentList = [
    {
        imgUrl: 'assets/images/group/01.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Be young, love life',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
    {
        imgUrl: 'assets/images/group/02.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Be young, love life',
        groupLabel: [
            {
                iconName: 'icofont-globe',
                text: 'Public',
            },
            {
                iconName: 'icofont-users-alt-3',
                text: '12+ Members',
            },
        ]
    },
]

class ActiveGroup extends Component {
    render() { 
        return (
            <div className="widget active-group">
                <div className="widget-inner">
                    <div className="widget-title">
                        <h5>{title}</h5>
                    </div>
                    <div className="widget-content">
                        {GroupContentList.map((val, i) => (
                            <div className="group-item lab-item" key={i}>
                                <div className="lab-inner">
                                    <div className="lab-thumb">
                                        <Link to="/active-group"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                    </div>
                                    <div className="lab-content text-center">
                                        <Link to="/active-group"><h4>{val.title}</h4></Link>
                                        <ul className="lab-content-icon">
                                            {val.groupLabel.map((val, i) => (
                                                <li key={i}>
                                                    <i className={val.iconName}></i>
                                                    <div className="time-tooltip">
                                                        <div className="time-tooltip-holder">
                                                            <span className="tooltip-label">{val.text}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ActiveGroup;