import { Component, Fragment } from "react";
import { Link, NavLink } from "react-router-dom";
import SelectDate from "../select/select-date";
import SelectGender from "../select/select-gender";
import SelectMonth from "../select/select-month";
import SelectYear from "../select/select-year";

const phoneNumber = "+800-123-4567 6587";
const address = "Beverley, New York 224 USA";

let HeaderInfoList = [
    {
        iconName: 'icofont-ui-call',
        text: '+800-123-4567 6587',
    },
    {
        iconName: 'icofont-location-pin',
        text: 'Beverley, New York 224 USA',
    },
]

let HeaderSocialList = [
    {
        iconName: 'icofont-facebook-messenger',
        link: '#'
    },
    {
        iconName: 'icofont-twitter',
        link: '#'
    },
    {
        iconName: 'icofont-vimeo',
        link: '#'
    },
    {
        iconName: 'icofont-skype',
        link: '#'
    },
    {
        iconName: 'icofont-rss-feed',
        link: '#'
    },
]

class Header extends Component {
    openSignUp() {
        document.querySelector('.popup').classList.toggle('open')
    }
    openLogIn() {
        document.querySelector('.popuplogin').classList.toggle('open')
    }
    menuTrigger() {
        document.querySelector('.menu').classList.toggle('active')
        document.querySelector('.header-bar').classList.toggle('active')
    }
    menuTriggerTwo() {
        document.querySelector('.header-top').classList.toggle('open')
    }

    constructor(props){
        super(props);
        this.state = {
            regName: '',
            regEmail: '',
            regPassword: '',
        };
    }


    render() { 
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 200) {
                document.querySelector('.header-section').classList.add(['header-fixed'], ['animated'], ['fadeInDown'])
            }else{
                document.querySelector('.header-section').classList.remove(['header-fixed'], ['animated'], ['fadeInDown'])
            }
        });
        return (
            <Fragment>
                <header className="header-section">
                    <div className="header-top">
                        <div className="container">
                            <div className="header-top-area">
                                <ul className="left">
                                    {HeaderInfoList.map((val, i) => (
                                        <li key={i}>
                                            <i className={val.iconName}></i> <span>{val.text}</span>
                                        </li>
                                    ))}
                                </ul>
                                <ul className="social-icons d-flex align-items-center">
                                    <li>
                                        <p>
                                            Find us on :
                                        </p>
                                    </li>
                                    {HeaderSocialList.map((val, i) => (
                                        <li key={i}><a href={val.link}><i className={val.iconName}></i></a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="header-bottom">
                        <div className="container">
                            <div className="header-wrapper">
                                <div className="logo">
                                    <Link to="/">
                                        <img src="assets/images/logo/logo.png" alt="logo" />
                                    </Link>
                                </div>
                                <div className="menu-area">
                                    <ul className="menu">
                                        <li className="menu-item-has-children">
                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10">Home</a>
                                            <ul className="submenu dropdown-menu">
                                                <li><NavLink to="/">Home Page One</NavLink></li>
                                                <li><NavLink to="/index-2">Home Page Two</NavLink></li>
                                                <li><NavLink to="/index-3">Home Page Three</NavLink></li>
                                            </ul>
                                        </li>

                                        <li className="menu-item-has-children">
                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10">Features</a>
                                            <ul className="submenu dropdown-menu">
                                                <li><NavLink to="/community">Community</NavLink></li>
                                                <li><NavLink to="/members">All Members</NavLink></li>
                                                <li><NavLink to="/profile">Member Profile</NavLink></li>
                                                <li><NavLink to="/active-group">Active Group</NavLink></li>
                                                <li><NavLink to="/group-single">Group Single</NavLink></li>
                                                <li><NavLink to="/pricing-plan">Pricing Plan</NavLink></li>
                                                <li><NavLink to="/login">Login</NavLink></li>
                                                <li><NavLink to="/signup">Sign Up</NavLink></li>
                                                <li><NavLink to="/forgetpassword">Forget Password</NavLink></li>
                                                <li><NavLink to="/policy">Privacy & Policy</NavLink></li>
                                                <li><NavLink to="/404">404 Page</NavLink></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10">Shops</a>
                                            <ul className="submenu dropdown-menu">
                                                <li><NavLink to="/shop">Shop</NavLink></li>
                                                <li><NavLink to="/shop-single">Shop Details</NavLink></li>
                                                <li><NavLink to="/shop-cart">Cart Page</NavLink></li>
                                            </ul>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,10">Blog</a>
                                            <ul className="submenu dropdown-menu">
                                                <li><NavLink to="/blog">Blog</NavLink></li>
                                                <li><NavLink to="/blog-single">Blog Single</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><NavLink to="/contact">Contact</NavLink></li>
                                    </ul>
                                    <a className="login" onClick={this.openLogIn}><i className="icofont-user"></i> <span>LOG IN</span> </a>
                                    <a className="signup" onClick={this.openSignUp}><i className="icofont-users"></i> <span>SIGN UP</span> </a>

                                    <div className="header-bar d-lg-none" onClick={this.menuTrigger}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <div className="ellepsis-bar d-lg-none" onClick={this.menuTriggerTwo}>
                                        <i className="icofont-info-square"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="popup">
                    <div className="container">
                        <div className="popup__area bgimg" style={{backgroundImage: "url(/assets/images/bg2.jpg)"}}>
                            <div className="popup__close" onClick={this.openSignUp}>
                                <i className="icofont-close"></i>
                            </div>
                            <div className="row g-4">
                                <div className="col-xl-6">
                                    <div className="popup__fild">
                                        <h2 className="mb-4">Members Register</h2>
                                        <form action="#" className="banner-form">
                                            <div className="gender">
                                                <label htmlFor="gender">I am a:</label>
                                                <div className="custom-select right">
                                                    <SelectGender select={'male'} />
                                                </div>
                                            </div>

                                            <div className="person">
                                                <label htmlFor="gender">I am here to:</label>
                                                <div className="custom-select right">
                                                    <SelectGender select={'female'} />
                                                </div>
                                            </div>

                                            <div className="age">
                                                <div className="right d-flex justify-content-between w-100">
                                                    <div className="input-fild">
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="item01"
                                                            value={this.state.regName}
                                                            onChange={(e)=>{this.setState({regName: e.target.value});}}
                                                            placeholder="Your Name *"
                                                        />
                                                    </div>

                                                    <div className="input-fild">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            id="item02"
                                                            value={this.state.regEmail}
                                                            onChange={(e)=>{this.setState({regEmail: e.target.value});}}
                                                            placeholder="Your Email *"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="age">
                                                <div className="right d-flex justify-content-between w-100">
                                                    <div className="custom-select">
                                                        <SelectDate select={'day'} />
                                                    </div>

                                                    <div className="custom-select">
                                                        <SelectMonth select={'month'} />
                                                    </div>

                                                    <div className="custom-select">
                                                        <SelectYear select={'year'} />
                                                    </div>
                                                </div>
                                            </div>

                                            <button className="" type="submit">Find Love Today!</button>
                                            <p>By continueing confirming that we are read and agreey to our <a href="#">Terms & Conditions</a>, <Link to="/policy">Privacy Policy</Link> and <Link to="/policy">Cookie Policy</Link> </p>
                                            <h6>Already a member? <Link to="/login">Login Now</Link></h6>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="popup__thumb">
                                        <img src="assets/images/popup/01.png" alt="popup" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="popuplogin">
                    <div className="container">
                        <div className="popup__area bgimg" style={{backgroundImage: "url(/assets/images/bg3.jpg)"}}>
                            <div className="popup__close" onClick={this.openLogIn}>
                                <i className="icofont-close"></i>
                            </div>
                            <div className="row g-4 align-items-center">
                                <div className="col-xl-6">
                                    <div className="popup__fild">
                                        <h2 className="mb-4">Members Login</h2>
                                        <form action="#" className="banner-form">
                                            <div className="age">
                                                <div className="right w-100">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="item02"
                                                        value={this.state.regEmail}
                                                        onChange={(e)=>{this.setState({regEmail: e.target.value});}}
                                                        placeholder="Enter yor email *"
                                                    />
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        id="item03"
                                                        value={this.state.regPassword}
                                                        onChange={(e)=>{this.setState({regPassword: e.target.value});}}
                                                        placeholder="*******"
                                                    />
                                                    <p className="text-end mb-0"><Link to="/forgetpassword" className="theme-color">forget password</Link></p>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" defaultChecked />
                                                    <label className="form-check-label" htmlFor="flexCheckChecked">Keep me logged in</label>
                                                    <p>Don't check this box if you're at a public shared computer</p>
                                                </div>
                                            </div>

                                            <button className="" type="submit">Login to my account</button>
                                            <h6>Not a member? <Link to="/signup">Join Free Now</Link></h6>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="popup__thumb">
                                        <img src="assets/images/popup/02.png" alt="popup" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
            
        );
    }
}
 
export default Header;