import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";


class Policy extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Privacy Policy'} curPage={'Privacy Policy'} />
                <section className="terms-section padding-tb">
                    <div className="container">
                        <div className="terms-content">
                            <div className="terms-header">
                                <h4>Privacy &amp; Policy</h4>
                                <p><span className="theme-color fw-bold">Last Updated:</span> June 1, 2022</p>
                            </div>
                            <div className="terms-text">
                                <p className="mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum, atque sed id architecto totam neque quis beatae voluptates laboriosam? Ipsam cupiditate esse with aspernatur, minima natus pariatur sapiente reprehenderit error aliquam saepe dolorem illum corporis molestias reiciendis laboriosam, animi inventore est sit culpa praesent et. Pariatur totam aliquam harum porro beatae commodi, expedita voluptatibus. Aliquam aspernatur, odit recusandae enim reprehenderit nisi perspiciatis perferendis ipsa deleniti nulla! Neque nobis possimus porro mollitia sint.</p>
                                <ol>
                                    <li>
                                        <h5>Introduction</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum, atque sed id architecto totam neque quis beatae voluptates laboriosam? Ipsam cupiditate esse with aspernatur, minima natus pariatur sapiente reprehenderit error aliquam saepe dolorem illum corporis molestias reiciendis laboriosam, animi inventore est sit culpa praesent et. Pariatur totam aliquam harum porro beatae commodi, expedita voluptatibus. Aliquam aspernatur, odit recusandae enim reprehenderit nisi perspiciatis perferendis ipsa deleniti nulla! Neque nobis possimus porro mollitia sint.</p>
                                    </li>
                                    <li>
                                        <h5>Accessing the Service</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum, atque sed id architecto totam neque quis beatae voluptates laboriosam? Ipsam cupiditate esse with aspernatur, minima natus pariatur sapiente reprehenderit error aliquam saepe dolorem illum corporis molestias reiciendis laboriosam, animi inventore est sit culpa praesent et. Pariatur totam aliquam harum porro beatae commodi, expedita voluptatibus. Aliquam aspernatur, odit recusandae enim reprehenderit nisi perspiciatis perferendis ipsa deleniti nulla! Neque nobis possimus porro mollitia sint.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum, atque sed id architecto totam neque quis beatae voluptates laboriosam? Ipsam cupiditate esse with aspernatur, minima natus pariatur sapiente reprehenderit error aliquam saepe dolorem illum corporis molestias reiciendis laboriosam, animi inventore est sit culpa praesent et. Pariatur totam aliquam harum porro beatae commodi, expedita voluptatibus. Aliquam aspernatur, odit recusandae enim reprehenderit nisi perspiciatis perferendis ipsa deleniti nulla! Neque nobis possimus porro mollitia sint.</p>
                                    </li>
                                    <li>
                                        <h5>Ownership</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum, atque sed id architecto totam neque quis beatae voluptates laboriosam? Ipsam cupiditate esse with aspernatur, minima natus pariatur sapiente reprehenderit error aliquam saepe dolorem illum corporis molestias reiciendis laboriosam, animi inventore est sit culpa praesent et. Pariatur totam aliquam harum porro beatae commodi, expedita voluptatibus. Aliquam aspernatur, odit recusandae enim reprehenderit nisi perspiciatis perferendis ipsa deleniti nulla! Neque nobis possimus porro mollitia sint.</p>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum, atque sed id architecto totam neque quis beatae voluptates laboriosam? Ipsam cupiditate esse with aspernatur, minima natus pariatur sapiente reprehenderit error aliquam saepe dolorem illum corporis molestias reiciendis laboriosam, animi inventore est sit culpa praesent et. Pariatur totam aliquam harum porro beatae commodi, expedita voluptatibus. Aliquam aspernatur, odit recusandae enim reprehenderit nisi perspiciatis perferendis ipsa deleniti nulla! Neque nobis possimus porro mollitia sint.</p>
                                    </li>
                                    <li>
                                        <h5>Third-Party Content and Services</h5>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum, atque sed id architecto totam neque quis beatae voluptates laboriosam? Ipsam cupiditate esse with aspernatur, minima natus pariatur sapiente reprehenderit error aliquam saepe dolorem illum corporis molestias reiciendis laboriosam, animi inventore est sit culpa praesent et. Pariatur totam aliquam harum porro beatae commodi, expedita voluptatibus. Aliquam aspernatur, odit recusandae enim reprehenderit nisi perspiciatis perferendis ipsa deleniti nulla! Neque nobis possimus porro mollitia sint.</p>
                                        <ol>
                                            <li>Use or attempt to use another user’s Account without authorization from such user;
                                            </li>
                                            <li>Pose as another person or entity;</li>
                                            <li>Distribute spam, including through sending unwanted NFTs to other users;</li>
                                            <li>
                                                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum iste laborum
                                                possimus nostrum harum impedit rerum porro quae dignissimos aut.
                                                <ul>
                                                    <li>Lorem ipsum dolor sit amet consectetur</li>
                                                    <li>Lorem ipsum dolor sit amet consectetur</li>
                                                    <li>Lorem ipsum dolor sit amet consectetur</li>
                                                    <li>Lorem ipsum dolor sit amet consectetur</li>
                                                </ul>
                                            </li>
                                            <li>Use the Service or data collected from our Service for any advertising or direct marketing activity (including without limitation, email marketing, SMS marketing, and telemarketing);</li>
                                            <li>Bypass or ignore instructions that control access to the Service, including attempting to circumvent any</li>
                                        </ol>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum, atque sed id architecto totam neque quis beatae voluptates laboriosam? Ipsam cupiditate esse with aspernatur, minima natus pariatur sapiente reprehenderit error aliquam saepe dolorem illum corporis molestias reiciendis laboriosam, animi inventore est sit culpa praesent et. Pariatur totam aliquam harum porro beatae commodi, expedita voluptatibus. Aliquam aspernatur, odit recusandae enim reprehenderit nisi perspiciatis perferendis ipsa deleniti nulla! Neque nobis possimus porro mollitia sint.</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}
 
export default Policy;