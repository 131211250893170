import { Component } from "react";

let RatingList = [
    {
        iconName: 'icofont-star',
    },
    {
        iconName: 'icofont-star',
    },
    {
        iconName: 'icofont-star',
    },
    {
        iconName: 'icofont-star',
    },
    {
        iconName: 'icofont-star',
    },
]

class Rating extends Component {
    render() { 
        return (
            <div className="rating">
                {RatingList.map((val, i) => (
                    <i key={i} className={val.iconName}></i>
                ))}
            </div>
        );
    }
}
 
export default Rating;