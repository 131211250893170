import { Component } from "react";

const title = "Download Our Pyaar Pro Apps";
const desc = "You find us, finally and you are already in love. More than 5.000.000 around world already shared the same experience andng ares uses our system Joining!";

let PalystoreList = [
    {
        imgUrl: 'assets/images/app/01.jpg',
        imgAlt: 'apps',
        Link: '#',
    },
    {
        imgUrl: 'assets/images/app/02.jpg',
        imgAlt: 'apps',
        Link: '#',
    }
]

class AppSection extends Component {
    render() { 
        return (
            <div className="app app--style2 padding-tb">
                <div className="container">
                    <div className="row g-4 justify-content-center">
                        <div className="col-12">
                            <div className="app__item wow fadeInUp" data-wow-duration="1.5s">
                                <div className="app__inner">
                                    <div className="section-header w-100">
                                        <h2>{title}</h2>
                                        <p>{desc}</p>
                                    </div>
                                    <div className="app__content text-center">
                                        <ul className="justify-content-center">
                                            {PalystoreList.map((val, i) => (
                                                <li key={i}><a href={`${val.Link}`}><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></a></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default AppSection;