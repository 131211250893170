import { Component, useState } from "react";
import { Link } from "react-router-dom";

const title = "Meet Our New Lovely Members";
const desc = "Learn from them and try to make it to this board. This will for sure boost you visibility and increase your chances to find you loved one.";
const btnText = "Discover All Members";


let MemberData = [
    {
        imgUrl: 'assets/images/member/01.jpg',
        imgAlt: 'Dating Member',
        name: 'Johanna',
        gender: '29. Male',
        catagory: 'boy',
        id: '1',
    },
    {
        imgUrl: 'assets/images/member/02.jpg',
        imgAlt: 'Dating Member',
        name: 'Johanna',
        gender: '29. Male',
        catagory: 'girl',
        id: '2',
    },
    {
        imgUrl: 'assets/images/member/03.jpg',
        imgAlt: 'Dating Member',
        name: 'Johanna',
        gender: '29. Male',
        catagory: 'boy',
        id: '3',
    },
    {
        imgUrl: 'assets/images/member/04.jpg',
        imgAlt: 'Dating Member',
        name: 'Johanna',
        gender: '29. Male',
        catagory: 'girl',
        id: '4',
    },
    {
        imgUrl: 'assets/images/member/05.jpg',
        imgAlt: 'Dating Member',
        name: 'Johanna',
        gender: '29. Male',
        catagory: 'boy',
        id: '5',
    },
    {
        imgUrl: 'assets/images/member/06.jpg',
        imgAlt: 'Dating Member',
        name: 'Johanna',
        gender: '29. Male',
        catagory: 'girl',
        id: '6',
    },
    {
        imgUrl: 'assets/images/member/07.jpg',
        imgAlt: 'Dating Member',
        name: 'Johanna',
        gender: '29. Male',
        catagory: 'girl',
        id: '7',
    },
    {
        imgUrl: 'assets/images/member/08.jpg',
        imgAlt: 'Dating Member',
        name: 'Johanna',
        gender: '29. Male',
        catagory: 'girl',
        id: '8',
    },
    {
        imgUrl: 'assets/images/member/09.jpg',
        imgAlt: 'Dating Member',
        name: 'Johanna',
        gender: '29. Male',
        catagory: 'boy',
        id: '9',
    },
    {
        imgUrl: 'assets/images/member/10.jpg',
        imgAlt: 'Dating Member',
        name: 'Johanna',
        gender: '29. Male',
        catagory: 'girl',
        id: '10',
    },
]


const MemberTwo = () => {
    const [items, setItems] = useState(MemberData);
    const filterItem = (categItem) => {
        const updateItems = MemberData.filter((curElem) => {
            return curElem.catagory === categItem;
        });
        setItems(updateItems);
    }

    return (
        <section className="top-member-section padding-tb">
            <div className="container">
                <div className="section-header">
                    <h2>{title}</h2>
                    <p>{desc}</p>
                </div>
                <div className="section-wrapper">
                    <ul className="button-group filters-button-group w-100 d-flex flex-wrap justify-content-center">
                        <li onClick={() => setItems(MemberData) } className="button filter-btn">Show all</li>
                        <li onClick={() => filterItem('boy') } className="button filter-btn">Male</li>
                        <li onClick={() => filterItem('girl') } className="button filter-btn">Female</li>
                    </ul>

                    <div className="grid-memberlist d-flex flex-wrap justify-content-center">
                        { items.map((elem) => {
                            const { id, imgUrl, imgAlt, name, gender } = elem;
                                return (
                                    <div className="grid-member filter-item" key={id}>
                                        <div className="lab-item member-item style-1">
                                            <div className="lab-inner">
                                                <div className="lab-thumb">
                                                    <Link to="/profile"><img src={imgUrl} alt={imgAlt} /></Link>
                                                </div>
                                                <div className="lab-content">
                                                    <h6><Link to="/profile">{name}</Link><i className="icofont-check" title="Verify Profile"></i> </h6>
                                                    <p>{gender}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="member-button-group d-flex flex-wrap justify-content-center">
                        <Link to="/members" className="lab-btn"><span>{btnText}</span></Link>
                    </div>
                </div>
            </div>
        </section>
    );
}
 
export default MemberTwo;