import { Component, Fragment } from "react";
import Footer from '../component/layout/footer';
import Header from '../component/layout/header';
import About from '../component/section/about';
import AppSection from '../component/section/appsection';
import Banner from '../component/section/banner';
import Group from '../component/section/group';
import Meet from '../component/section/meet';
import Member from '../component/section/member';
import Story from '../component/section/story';
import Work from '../component/section/work';

class Home extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <Banner />
                <Member />
                <About />
                <Group />
                <Story />
                <Meet />
                <Work />
                <AppSection />
                <Footer />
            </Fragment>
        );
    }
}
 
export default Home;