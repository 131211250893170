import { Component } from "react";

const title = "Our Popular tags";

let TagsList = [
    {
        link: '#',
        text: 'Dating',
    },
    {
        link: '#',
        text: 'Love',
    },
    {
        link: '#',
        text: 'Ollya',
    },
    {
        link: '#',
        text: 'health',
    },
    {
        link: '#',
        text: 'Partner',
    },
    {
        link: '#',
        text: 'Man',
    },
    {
        link: '#',
        text: 'Male',
    },
    {
        link: '#',
        text: 'Date',
    },
    {
        link: '#',
        text: 'Women',
    },
]

class Tags extends Component {
    render() { 
        return (
            <div className="widget widget-tags">
                <div className="widget-header">
                    <h5>{title}</h5>
                </div>
                <ul className="lab-ul widget-wrapper">
                    {TagsList.map((val, i) => (
                        <li key={i}><a href={val.link}>{val.text}</a></li>
                    ))}
                </ul>
            </div>
        );
    }
}
 
export default Tags;