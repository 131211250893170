import { Component } from "react";

const title = "How Does It Work?";
const desc = "get started on pyaar.com today in 3 simple steps";

let AboutThreeContnetList = [
    {
        imgUrl: 'assets/images/about/01.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Create A Profile',
        desc: 'Create a personalised profile, add photos and describe your ideal partner.',
    },
    {
        imgUrl: 'assets/images/about/02.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Browse Photos',
        desc: 'Find members based on location, very special interests and lifestyle preferences.',
    },
    {
        imgUrl: 'assets/images/about/03.jpg',
        imgAlt: 'Dating Thumb',
        title: 'Start Communicating',
        desc: 'Show interest in the members you like and let the journey begin.',
    },
]

class AboutThree extends Component {
    render() { 
        return (
            <div className="about padding-tb">
                <div className="container">
                    <div className="section-header wow fadeInUp" data-wow-duration="1.5s">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="section__wrapper">
                        <div className="row g-4 justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
                            {AboutThreeContnetList.map((val, i) => (
                                <div className="col wow fadeInUp" data-wow-duration="1.5s" key={i}>
                                    <div className="about__item text-center">
                                        <div className="about__inner">
                                            <div className="about__thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="about__content">
                                                <h4>{val.title}</h4>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default AboutThree;