import { Component } from "react";
import { Link } from "react-router-dom";

const title = 'Meet Our New Lovely Members';
const desc = 'Learn from them and try to make it to this board. This will for sure boost you visibility and increase your chances to find you loved one.';
const btnText = "Discover All Members";

let MemberList = [
    {
        imgUrl: 'assets/images/member/01.jpg',
        imgAlt: 'Dating Member',
        name: 'Cassey Dullea',
        gender: '28. Male',
    },
    {
        imgUrl: 'assets/images/member/02.jpg',
        imgAlt: 'Dating Member',
        name: 'Mercilla Bellez',
        gender: '29. Female',
    },
    {
        imgUrl: 'assets/images/member/03.jpg',
        imgAlt: 'Dating Member',
        name: 'Vernon Dahmer',
        gender: '24. Male',
    },
    {
        imgUrl: 'assets/images/member/04.jpg',
        imgAlt: 'Dating Member',
        name: 'Chynna Phillips',
        gender: '23. Female',
    },
    {
        imgUrl: 'assets/images/member/05.jpg',
        imgAlt: 'Dating Member',
        name: 'Elena Bulleati ',
        gender: '22. Male',
    },
]

class Member extends Component {
    render() { 
        return (
            <section className="member-section padding-tb">
                <div className="container">
                    <div className="section-header">
                        <h2>{title}</h2>
                        <p>{desc}</p>
                    </div>
                    <div className="section-wrapper">
                        <div className="row justify-content-center g-3 g-md-4 row-cols-xl-5 row-cols-md-3 row-cols-1">
                            {MemberList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="lab-item member-item style-1">
                                        <div className="lab-inner">
                                            <div className="lab-thumb">
                                                <Link to="/profile"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                            </div>
                                            <div className="lab-content">
                                                <h6><Link to="/profile">{val.name}<i className="icofont-check" title="Verify Profile"></i></Link> </h6>
                                                <p>{val.gender}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="member-button-group d-flex flex-wrap justify-content-center">
                            <Link to="/members" className="lab-btn"><span>{btnText}</span></Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Member;