import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const title = "Forgot Password?";
const btnText = "Send Password";

class ForgetPassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            forgetEmail: '',
        };
    }
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Forget Password Page'} curPage={'Forget Password'} />
                <div className="login-section padding-tb">
                    <div className=" container">
                        <div className="account-wrapper">
                            <h3 className="title">{title}</h3>
                            <form className="account-form">
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        id="item01"
                                        value={this.state.forgetEmail}
                                        onChange={(e)=>{this.setState({forgetEmail: e.target.value});}}
                                        placeholder="Your Email *"
                                    />
                                </div>
                                <div className="form-group mb-2">
                                    <button className="d-block lab-btn mb-0"><span>{btnText}</span></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}
 
export default ForgetPassword;