import { Component } from "react";

class SelectHobby extends Component {
    render() { 
        const {select} = this.props;
        return (
            <select defaultValue={select}>
                <option value="Interests">Your Interests </option>
                <option value="Gaming">Gaming</option>
                <option value="Fishing">Fishing</option>
                <option value="Skydriving">Skydriving</option>
                <option value="Swimming">Swimming</option>
                <option value="Racing">Racing</option>
                <option value="Hangout">Hangout</option>
                <option value="Tranvelling">Tranvelling</option>
                <option value="Camping">Camping</option>
                <option value="Touring">Touring</option>
                <option value="Acting">Acting</option>
                <option value="Dancing">Dancing</option>
                <option value="Singing">Singing</option>
            </select>
        );
    }
}
 
export default SelectHobby;