import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const subtitle = "Membership Levels";
const title = "Choose Our Pricing Plan";

let PricingPlanList = [
    {
        title: 'Basic Package',
        price: '$29.00',
        duration: '$29.00 Now And Then $90.00 Per Month.',
        btnText: 'Purchase Now',
        fasList: [
            {
                iconName: 'icofont-check-circled',
                text: 'View members directory',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'View members profile',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Access group directory',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Access to groups',
            },
            {
                iconName: 'icofont-close-circled',
                text: 'View site activity',
            },
            {
                iconName: 'icofont-close-circled',
                text: 'Send Private messages',
            },
            {
                iconName: 'icofont-close-circled',
                text: 'Add media to your profile',
            },
        ],
    },
    {
        title: 'Silver Package',
        price: '$290.00',
        duration: '$290.00 Now And Then $390.00 Per Month.',
        btnText: 'Purchase Now',
        fasList: [
            {
                iconName: 'icofont-check-circled',
                text: 'View members directory',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'View members profile',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Access group directory',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Access to groups',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'View site activity',
            },
            {
                iconName: 'icofont-close-circled',
                text: 'Send Private messages',
            },
            {
                iconName: 'icofont-close-circled',
                text: 'Add media to your profile',
            },
        ],
    },
    {
        title: 'Gold Package',
        price: '$390.00',
        duration: '$390.00 Now And Then $490.00 Per Month.',
        btnText: 'Purchase Now',
        fasList: [
            {
                iconName: 'icofont-check-circled',
                text: 'View members directory',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'View members profile',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Access group directory',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Access to groups',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'View site activity',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Send Private messages',
            },
            {
                iconName: 'icofont-check-circled',
                text: 'Add media to your profile',
            },
        ],
    },
]

class PricingPlan extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Membership Level'} curPage={'Membership'} />
                <section className="pricing-section padding-tb">
                    <div className="container">
                        <div className="section-header">
                            <h4 className="theme-color">{subtitle}</h4>
                            <h2>{title}</h2>
                        </div>
                        <div className="section-wrapper mt-4">
                            <div className="pricing-plan-wrapper">
                                <div className="row g-4 justify-content-center">
                                    {PricingPlanList.map((val, i) => (
                                        <div className="col-lg-4 col-sm-6" key={i}>
                                            <div className="price-item">
                                                <div className="price-item-inner">
                                                    <div className="price-top">
                                                        <h6>{val.title}</h6>
                                                        <h2>{val.price}</h2>
                                                        <p>{val.duration}</p>
                                                    </div>
                                                    <div className="price-bottom">
                                                        <ul>
                                                            {val.fasList.map((val, i) => (
                                                                <li key={i}><i className={val.iconName}></i>
                                                                    <p>{val.text}</p>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        <Link to="/login" className="purchase-btn">{val.btnText}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}
 
export default PricingPlan;