import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";

const title = "Login";
const socialTitle = "Login With Social Media";

let SocialList = [
    {
        iconName: 'icofont-facebook-messenger',
        link: '#',
        className: 'facebook',
    },
    {
        iconName: 'icofont-twitter',
        link: '#',
        className: 'twitter',
    },
    {
        iconName: 'icofont-linkedin',
        link: '#',
        className: 'linkedin',
    },
    {
        iconName: 'icofont-instagram',
        link: '#',
        className: 'instagram',
    },
    {
        iconName: 'icofont-pinterest',
        link: '#',
        className: 'pinterest',
    },
]

class LogIn extends Component {
    constructor(props){
        super(props);
        this.state = {
            userEmail: '',
            userPass: '',
        };
    }
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Login Page'} curPage={'Login'} />
                <div className="login-section padding-tb">
                    <div className=" container">
                        <div className="account-wrapper">
                            <h3 className="title">{title}</h3>
                            <form className="account-form">
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        id="item01"
                                        value={this.state.userEmail}
                                        onChange={(e)=>{this.setState({userEmail: e.target.value});}}
                                        placeholder="Enter Your Email *"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        name="password"
                                        id="item02"
                                        value={this.state.userPass}
                                        onChange={(e)=>{this.setState({userPass: e.target.value});}}
                                        placeholder="Enter Your Password *"
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="d-flex justify-content-between flex-wrap pt-sm-2">
                                        <div className="checkgroup">
                                            <input type="checkbox" name="remember" id="remember" />
                                            <label htmlFor="remember">Remember Me</label>
                                        </div>
                                        <Link to="/forgetpassword">Forget Password?</Link>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button className="d-block lab-btn"><span>Submit Now</span></button>
                                </div>
                            </form>
                            <div className="account-bottom">
                                <span className="d-block cate pt-10">Don’t Have any Account? <Link to="/signup"> Sign Up</Link></span>
                                <span className="or"><span>or</span></span>
                                <h5 className="subtitle">{socialTitle}</h5>
                                <ul className="social-media social-color lab-ul d-flex justify-content-center">
                                    {SocialList.map((val, i) => (
                                        <li key={i}>
                                            <a href={val.link} className={val.className}><i className={val.iconName}></i></a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}
 
export default LogIn;