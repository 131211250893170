import { Component, Fragment } from "react";

import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import Rating from "../component/section/rating";
import SelectColor from "../component/select/select-color";
import SelectSize from "../component/select/select-size";
import Category from "../component/sidebar/category";
import RecentProduct from "../component/sidebar/recent-product";
import Search from "../component/sidebar/search";

const title = "Product Text here";
const subtitle = "Product Description";
const desc = "Energistia an deliver atactica metrcs after avsionary Apropria trnsition enterpris an sources applications emerging 	psd template communities.";
const price = "$ 340.00";


let SliderImg = [
    {
        imgUrl: 'assets/images/shop/01.jpg',
        imgAlt: 'Product Thumb',
    },
    {
        imgUrl: 'assets/images/shop/02.jpg',
        imgAlt: 'Product Thumb',
    },
    {
        imgUrl: 'assets/images/shop/03.jpg',
        imgAlt: 'Product Thumb',
    },
    {
        imgUrl: 'assets/images/shop/04.jpg',
        imgAlt: 'Product Thumb',
    },
    {
        imgUrl: 'assets/images/shop/05.jpg',
        imgAlt: 'Product Thumb',
    },

]


let ReviewList = [
    {
        imgUrl: 'assets/images/clients/01.jpg',
        imgAlt: 'Client thumb',
        name: 'Rajib Raj',
        date: 'Posted on May 25, 2022 at 6:57 am',
        desc: 'Enthusiast build innovativ initiatives before lonterm high-impact awesome theme seo psd porta monetize covalent leadership after without resource.',
    },
    {
        imgUrl: 'assets/images/clients/02.jpg',
        imgAlt: 'Client thumb',
        name: 'Angel Mili',
        date: 'Posted on May 25, 2022 at 6:57 am',
        desc: 'Enthusiast build innovativ initiatives before lonterm high-impact awesome theme seo psd porta monetize covalent leadership after without resource.',
    },
    {
        imgUrl: 'assets/images/clients/03.jpg',
        imgAlt: 'Client thumb',
        name: 'Telford Bois',
        date: 'Posted on May 25, 2022 at 6:57 am',
        desc: 'Enthusiast build innovativ initiatives before lonterm high-impact awesome theme seo psd porta monetize covalent leadership after without resource.',
    },
    {
        imgUrl: 'assets/images/clients/04.jpg',
        imgAlt: 'Client thumb',
        name: 'Zinat Zaara',
        date: 'Posted on May 25, 2022 at 6:57 am',
        desc: 'Enthusiast build innovativ initiatives before lonterm high-impact awesome theme seo psd porta monetize covalent leadership after without resource.',
    },
]

class ShopSingle extends Component {
    constructor(props){
        super(props);
        this.state = {
            reviwName: '',
            reviwEmail: '',
            reviwMassage: '',
        };
    }
    reviewShow() {
        document.querySelector('.review-content').classList.add('review-content-show')
        document.querySelector('.review-content').classList.remove('description-show')
        document.querySelector('.review-nav').classList.add('RevActive')
        document.querySelector('.review-nav').classList.remove('DescActive')
    }
    descriptionShow() {
        document.querySelector('.review-content').classList.add('description-show')
        document.querySelector('.review-content').classList.remove('review-content-show')
        document.querySelector('.review-nav').classList.add('DescActive')
        document.querySelector('.review-nav').classList.remove('RevActive')
    }
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'Shop Details Page'} curPage={'Shop Details'} />
                <div className="shop-single padding-tb">
                    <div className="container">
                        <div className="row g-4 justify-content-center">
                            <div className="col-xl-8 col-12">
                                <article>
                                    <div className="product-details">
                                        <div className="row g-4 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="product-thumb">
                                                    <div className="swiper-container pro-single-top">
                                                        <Swiper
                                                            loop={'true'}
                                                            autoplay={{
                                                                delay: 5000,
                                                                disableOnInteraction: false,
                                                            }}
                                                            navigation={{
                                                                prevEl: '.pro-single-prev',
                                                                nextEl: '.pro-single-next',
                                                            }}
                                                            modules={[Autoplay, Navigation]}
                                                        >
                                                            {SliderImg.map((val, i) => (
                                                                <SwiperSlide key={i}>
                                                                    <div className="single-thumb">
                                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                                    </div>
                                                                </SwiperSlide>
                                                            ))} 
                                                        </Swiper>
                                                    </div>
                                                    
                                                    <div className="pro-single-next"><i className="icofont-rounded-left"></i></div>
                                                    <div className="pro-single-prev"><i className="icofont-rounded-right"></i></div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="post-content">
                                                    <h4>{title}</h4>
                                                    <p className="rating">
                                                        <i className="icofont-star"></i>
                                                        <i className="icofont-star"></i>
                                                        <i className="icofont-star"></i>
                                                        <i className="icofont-star"></i>
                                                        <i className="icofont-star"></i>
                                                        (3 review)
                                                    </p>
                                                    <h4>{price}</h4>
                                                    <h6>{subtitle}</h6>
                                                    <p>{desc}</p>
                                                    <form>
                                                        <div className="select-product size">
                                                            <SelectSize select={'SM'} />
                                                            <i className="icofont-rounded-down"></i>
                                                        </div>
                                                        <div className="select-product color">
                                                            <SelectColor select={'Blue'} />
                                                            <i className="icofont-rounded-down"></i>
                                                        </div>
                                                        <div className="discount-code">
                                                            <input type="text" placeholder="Enter Discount Code" />
                                                        </div>
                                                        <div className="cart-plus-minus">
                                                            <div className="dec qtybutton">-</div>
                                                            <input className="cart-plus-minus-box" type="text" name="qtybutton" defaultValue="1" />
                                                            <div className="inc qtybutton">+</div>
                                                        </div>
                                                        <button type="submit" className="lab-btn"><span>Add To Cart</span></button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="review">
                                        <ul className="review-nav lab-ul RevActive">
                                            <li className="desc" onClick={this.descriptionShow}>Description</li>
                                            <li className="rev" onClick={this.reviewShow}>Reviews 4</li>
                                        </ul>
                                        <div className="review-content review-content-show">
                                            <div className="review-showing">
                                                <ul className="content lab-ul">
                                                    {ReviewList.map((val, i) => (
                                                        <li key={i}>
                                                            <div className="post-thumb">
                                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                            </div>
                                                            <div className="post-content">
                                                                <div className="entry-meta">
                                                                    <div className="posted-on">
                                                                        <a href="#">{val.name}</a>
                                                                        <p>{val.date}</p>
                                                                    </div>
                                                                    <Rating />
                                                                </div>
                                                                <div className="entry-content">
                                                                    <p>{val.desc}</p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className="client-review">
                                                    <div className="review-form">
                                                        <div className="review-title">
                                                            <h5>Add a Review</h5>
                                                        </div>
                                                        <form action="action" className="row">
                                                            <div className="col-md-4 col-12">
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    id="item01"
                                                                    value={this.state.reviwName}
                                                                    onChange={(e)=>{this.setState({reviwName: e.target.value});}}
                                                                    placeholder="Full Name *"
                                                                />
                                                            </div>
                                                            <div className="col-md-4 col-12">
                                                                <input
                                                                    type="text"
                                                                    name="email"
                                                                    id="item02"
                                                                    value={this.state.reviwEmail}
                                                                    onChange={(e)=>{this.setState({reviwEmail: e.target.value});}}
                                                                    placeholder="Your Email *"
                                                                />
                                                            </div>
                                                            <div className="col-md-4 col-12">
                                                                <div className="rating">
                                                                    <span className="rating-title">Your Rating : </span>
                                                                    <Rating />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-12">
                                                                <textarea rows="5" placeholder="Type Here Message"></textarea>
                                                            </div>
                                                            <div className="col-12">
                                                                <button className="lab-btn" type="submit"><span>Submit Review</span></button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="description">
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                                                <div className="post-item">
                                                    <div className="post-thumb">
                                                        <img src="assets/images/shop/01.jpg" alt="shop" />
                                                    </div>
                                                    <div className="post-content">
                                                        <ul className="lab-ul">
                                                            <li>
                                                                Donec non est at libero vulputate rutrum.
                                                            </li>
                                                            <li>
                                                                Morbi ornare lectus quis justo gravida semper.
                                                            </li>
                                                            <li>
                                                                Pellentesque aliquet, sem eget laoreet ultrices.
                                                            </li>
                                                            <li>
                                                                Nulla tellus mi, vulputate adipiscing cursus eu, suscipit id nulla.
                                                            </li>
                                                            <li>
                                                                Donec a neque libero.
                                                            </li>
                                                            <li>
                                                                Pellentesque aliquet, sem eget laoreet ultrices.
                                                            </li>
                                                            <li>
                                                                Morbi ornare lectus quis justo gravida semper..
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                                    cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                                                    proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>

                            <div className="col-xl-4 col-12">
                                <aside>
                                    <Search />
                                    <Category />
                                    <RecentProduct />
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}
 
export default ShopSingle;