import { Component } from "react";
import SelectAge from "../select/select-age";
import SelectGender from "../select/select-gender";

const title = "Find Your Partner.";
const subtitle = "You’re Looking for…";
const desc = "Serious dating with your perfect match is just a click away.";
const btnText = "Find Your Partner";

class Banner extends Component {
    render() { 
        return (
            <section className="banner-section bgimg" style={{backgroundImage: "url(/assets/images/banner/bg.jpg)"}}>
                <div className="container">
                    <div className="section-wrapper">
                        <div className="row align-items-end">
                            <div className="col-lg-6">
                                <div className="banner-content">
                                    <div className="intro-form">
                                        <div className="intro-form-inner">
                                            <h2>{title}</h2>
                                            <p>{desc}</p>
                                            <h6 className="mb-3">{subtitle}</h6>
                                            <form action="#" className="banner-form">
                                                <div className="gender">
                                                    <div className="custom-select right">
                                                        <SelectGender select={'male'} />
                                                    </div>
                                                </div>
                                                <div className="person">
                                                    <div className="custom-select right">
                                                        <SelectGender select={'female'} />
                                                    </div>
                                                </div>
                                                <div className="age">
                                                    <div className="right d-flex justify-content-between w-100">
                                                        <div className="custom-select">
                                                            <SelectAge select={'18'} />
                                                        </div>

                                                        <div className="custom-select">
                                                            <SelectAge select={'25'} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit">{btnText}</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Banner;