import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';

import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import ActiveGroup from "./page/active-group";
import BlogPage from "./page/blog";
import BlogSingle from "./page/blog-single";
import CommunityPage from "./page/community";
import ContactPage from "./page/contact";
import ForgetPassword from "./page/forgetpassword";
import GroupSingle from "./page/group-single";
import Home from "./page/home";
import HomeThree from "./page/homethree";
import HomeTwo from "./page/hometwo";
import LogIn from "./page/login";
import MemberPage from "./page/member";
import Policy from "./page/policy";
import PricingPlan from "./page/pricing-plan";
import ProfilePage from "./page/profile";
import ShopPage from "./page/shop";
import ShopCart from "./page/shop-cart";
import ShopSingle from "./page/shop-single";
import SignUp from "./page/signup";

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="index-2" element={<HomeTwo />} />
				<Route path="index-3" element={<HomeThree />} />
				<Route path="community" element={<CommunityPage />} />
				<Route path="members" element={<MemberPage />} />
				<Route path="profile" element={<ProfilePage />} />
				<Route path="active-group" element={<ActiveGroup />} />
				<Route path="group-single" element={<GroupSingle />} />
				<Route path="pricing-plan" element={<PricingPlan />} />
				<Route path="login" element={<LogIn />} />
				<Route path="signup" element={<SignUp />} />
				<Route path="forgetpassword" element={<ForgetPassword />} />
				<Route path="policy" element={<Policy />} />
				<Route path="*" element={<ErrorPage />} />
				<Route path="shop" element={<ShopPage />} />
				<Route path="shop-single" element={<ShopSingle />} />
				<Route path="shop-cart" element={<ShopCart />} />
				<Route path="blog" element={<BlogPage />} />
				<Route path="blog-single" element={<BlogSingle />} />
				<Route path="contact" element={<ContactPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
